.form {
    flex-grow: 1;
}
.optionContainer {
    width: 100%;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1rem;
}
.optionText {
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 100%;
    height: 3rem;
    border-radius: 1.5rem;
    background: #f8f8f8;
    box-shadow: 8px 8px 17px #d3d3d3, -5px -5px 10px #ffffff;
    // box-shadow: 5px 5px 10px #c4c4c4, -5px -5px 10px #ffffff;
}
.optionCheckbox {
    &:hover {
        background: linear-gradient(145deg, #dfdfdf, #fff);
    }
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 3rem;
    min-width: 3rem;
    border-radius: 1.5rem;
    background: linear-gradient(145deg, #fff, #dfdfdf);
    box-shadow: 5px 5px 10px #c4c4c4, -5px -5px 10px #ffffff;
}
.activeCheckbox {
    justify-content: center;
    height: 3rem;
    min-width: 3rem;
    border-radius: 1.5rem;
    box-shadow: 5px 5px 10px #c4c4c4, -5px -5px 10px #ffffff;
    background: linear-gradient(145deg, #0ee7c3, #06caaa) !important;
}

.noAnswer {
    padding-left: 6px;
    margin-top: 0.5rem;
    cursor: pointer;
    text-decoration: underline;
    text-align: center;
    font-size: 0.8rem;

    @media only screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
        position: fixed;
        bottom: 0.5rem;
        left: 0;
        font-size: 0.9rem;
    }
}
@media only screen and (max-width: 768px) {
    .buttonOffset {
        bottom: 1.2rem !important;
    }
}
