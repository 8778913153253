.listItem {
    margin-bottom: 2rem;
    padding: 2rem;
    border-radius: 1.5rem;
    background: #f8f8f8;
    box-shadow: 5px 5px 15px #cbcbcb, -5px -5px 15px #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    @media only screen and (max-width: 768px) {
        padding: 1rem;
    }
}
.divider {
    min-width: 1px;
    max-width: 1px;
    background-color: #898f99;
    min-height: 2rem;
    align-self: stretch;
}
.link {
    white-space: pre-wrap;
    word-break: break-all;
}
.flex {
    flex-grow: 1;
}
.buttonAlign {
    align-self: flex-end;
}
