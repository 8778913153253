.headerContainer {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    font-size: 1rem;
    height: 3rem;
    box-shadow: 0px 5px 5px #eeeeee;
    background-color: #f8f8f8;
}
.logo {
    font-size: 1rem;
    font-weight: 700;
    color: #000;
    text-decoration: none;
}
.headerItems {
    display: flex;
    gap: 1rem;
    margin-right: 2rem;
}
.link {
    text-decoration: none;
    font-weight: 700;
    color: #898f99;
}
.link:hover {
    color: #000;
}
.infoBar {
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 1.5rem;
    text-align: center;
    color: #fff;
    font-size: 0.8rem;
    position: fixed;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.close {
    cursor: pointer;
    padding-right: 0.5rem;
}
